// color variants
@import "themes-vars.module.scss";

// third-par
@import " ~react-perfect-scrollbar/dist/css/styles.css";
@import " ~slick-carousel/slick/slick.css";
@import " ~slick-carousel/slick/slick-theme.css";

@import " ~react-18-image-lightbox/style.css";

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

div#preload {
  display: none;
}

// testing

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //
.mission_projects_priciples_box {
  flex: 1;
}

.mission_home_projects_priciples_box {
  flex: 1;
}

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
  overflow: hidden;
  .slider {
    .slide {
      opacity: 0.5;
      display: flex;
      justify-content: center;
      .custom-slider {
        gap: 40px;
      }
      &.selected {
        opacity: 1;
        .custom-slider {
          display: flex;
          flex-direction: column;
        }
      }
      &:not(.selected) {
        transform: scale(0.7);
        transform-origin: top;
      }
    }
  }
}

.project-info {
  .project-slider {
    .slick-list {
      padding: 0 !important;
    }
  }
  .slick-slide {
    opacity: 0.05;
  }
  .slick-active {
    opacity: 0.2;
  }
  .slick-current {
    opacity: 1 !important;
    .MuiTypography-root {
      color: $primaryMain;
    }
  }
}

.ReactModal__Overlay {
  z-index: 99999 !important;
}

// ==============================|| Custom ||============================== //

.hs-form fieldset {
  max-width: none !important;
}

.icon-tabler {
  color: $primaryMain !important;
}

// new website css

body {
  font-family: "Manrope" !important;
}

.homepage_appbar {
  /* Navbar / 1 / */

  /* Auto layout */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  position: fixed;
  top: 0;

  height: 90px;

  /* White */
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #000000;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage_appbar_container {
  // /* Container */

  /* Auto layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 100%;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.homepage_appbar_right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.homepage_appbar_logo {
  /* logo 1 */

  display: flex;
  flex-direction: column;
  gap: 0.2em;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage_appbar_nav_get_started {
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  /* Button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 8px;

  height: 48px;

  background: #2ca4e6;
  border-radius: 48px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;

  border: none;

  /* Sign up */

  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #ffffff;

  // border: 1px solid #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_learn_more {
  width: 100%;
}

.homepage_appbar_nav_get_started_learn_more {
  cursor: pointer;
  width: 100%;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  /* Button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 8px;

  height: 48px;

  background: #2ca4e6;
  border-radius: 48px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;

  border: none;

  /* Sign up */

  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #ffffff;

  // border: 1px solid #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage_appbar_nav {
  /* Column */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 0.5em;

  height: 48px;

  /* White */
  background: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.homepage_appbar_nav_links {
  /* Column */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 0.5em;
  /* height: 24px; */
  flex: none;
  order: 0;
  flex-grow: 0;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage_appbar_nav_link {
  /* Link */

  height: 24px;

  text-decoration: none;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #010305;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

// /* Nav Link Dropdown */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: center;
// align-items: center;
// padding: 0px;
// gap: 4px;

// width: 46px;
// height: 24px;

// /* Inside auto layout */
// flex: none;
// order: 3;
// flex-grow: 0;

// /* Link */

// width: 46px;
// height: 24px;

// font-family: 'Manrope';
// font-style: normal;
// font-weight: 500;
// font-size: 16px;
// line-height: 150%;
// /* identical to box height, or 24px */

// color: #010305;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Column */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: center;
// padding: 0px;
// gap: 16px;

// width: 153px;
// height: 48px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* Button */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: center;
// align-items: center;
// padding: 12px 32px;
// gap: 8px;

// width: 153px;
// height: 48px;

// background: #2CA4E6;
// border-radius: 48px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Sign up */

// width: 89px;
// height: 24px;

// font-family: 'Manrope';
// font-style: normal;
// font-weight: 700;
// font-size: 16px;
// line-height: 150%;
// /* identical to box height, or 24px */

// color: #FFFFFF;

// border: 1px solid #000000;
// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Frame 130 */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: center;
// align-items: center;
// padding: 0px;
// gap: 8px;

// width: 75px;
// height: 24px;

// /* Inside auto layout */
// flex: none;
// order: 2;
// flex-grow: 0;

// /* Link */

// width: 51px;
// height: 24px;

// font-family: 'Manrope';
// font-style: normal;
// font-weight: 500;
// font-size: 16px;
// line-height: 150%;
// /* identical to box height, or 24px */

// color: #010305;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* user 1 */

// width: 16px;
// height: 16px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* Vector */

// position: absolute;
// left: 25%;
// right: 25%;
// top: 8.33%;
// bottom: 41.67%;

// border: 1.5px solid #000000;

// /* Vector */

// position: absolute;
// left: 12.5%;
// right: 12.5%;
// top: 58.33%;
// bottom: 8.33%;

// border: 1.5px solid #000000;

.homepage_hero_container {
  height: 100%;
  margin: 20px;
  position: relative;
  // margin-bottom: 112px;
  display: flex;
  justify-content: center;
}

.homepage_hero_container_video {
  width: 100%;
  height: 700px;
  border-radius: 16px;
  left: -10px; /* Shifts the video 10px to the left */
  object-fit: cover;
}

.homepage_hero_textbox {
  position: absolute;
  top: 48%; /* Adjust as needed */
  left: 75%;
  transform: translate(-50%, -50%);
  // background-color: rgba(0, 0, 0, 0.2); /* Optional background for readability */

  color: white; /* Adjust color as needed */
  padding: 10px; /* Adjust padding as needed */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 24px;
}

.homepage_hero_section_title {
  /* Section Title */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.homepage_hero_frame129 {
  /* Frame 129 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  backdrop-filter: blur(26px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 56px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage_hero_frame129_heading {
  /* Heading */

  height: 19px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage_hero_heading {
  /* Heading */

  width: 530px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 2.8em;
  line-height: 120%;
  /* or 67px */
  letter-spacing: -0.03em;

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.homepage_hero_text {
  // /* Text */

  width: 530px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 170%;
  /* or 31px */

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

// /* Actions */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: flex-end;
// padding: 16px 0px 0px;
// gap: 16px;

// width: 401px;
// height: 72px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* Button */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: center;
// align-items: center;
// padding: 16px 48px;
// gap: 8px;

// width: 185px;
// height: 56px;

// background: #2CA4E6;
// border-radius: 48px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Sign up */

// width: 89px;
// height: 24px;

// font-family: 'Manrope';
// font-style: normal;
// font-weight: 700;
// font-size: 16px;
// line-height: 150%;
// /* identical to box height, or 24px */

// color: #FFFFFF;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Frame 116 */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: flex-start;
// padding: 0px;
// gap: 16px;

// width: 200px;
// height: 40px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* Frame 115 */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: space-between;
// align-items: center;
// padding: 0px;
// gap: 16px;

// width: 200px;
// height: 24px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// align-self: stretch;
// flex-grow: 0;

// /* Watch video */

.heropage_hero_action {
  display: flex;
  gap: 16px;
  align-items: center;
}

.homepage_appbar_nav_watch_video {
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 30px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-transform: capitalize;

  color: #ffffff;

  border-bottom: #ffffff 1.5px solid;

  padding: 10px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

// /* Arrow 1 */

// box-sizing: border-box;

// margin: 0 auto;
// width: 16px;
// height: 0px;

// border: 1.5px solid #FFFFFF;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* Line 1 */

// width: 200px;
// height: 0px;

// border: 1.5px solid #FFFFFF;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

/* Gallery / 20 / */

.homepage_project_container {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 0em 1em 4em 1em;
  gap: 3em;

  /* White */
  background: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.container_spacing {
  margin-bottom: 3em;
}

.bg_image_1 {
  background-image: url("../images/bgImages/1.webp"); /* Replace with your image path */
  background-size: cover; /* Makes sure the image covers the entire section */
  background-position: center; /* Keeps the image centered */
  color: white; /* Optional: to make the text readable against the image */
  border-radius: 16px;
}

.bg_image_2 {
  position: relative;
  background-image: url("../images/bgImages/2.webp");
  background-size: cover;
  background-position: center;
  margin-bottom: 3rem;
  color: white;
  border-radius: 16px;
}

.bg_image_3 {
  position: relative;
  background-image: url("../../assets/images/map.svg");
  background-size: contain;
  background-position: center;
  margin-bottom: 3rem;
  color: white;
  border-radius: 16px;
}

.bg_image_4 {
  background-image: url("../images/bgImages/8.webp");
}

/* Ensure text stays above the overlay */
.partner_bg_image_2 * {
  position: relative;
  z-index: 2;
}

.bg_blur {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 3em 0; /* Adjust the padding as per your design */
}

.remove_bg_properties {
  background: none;
}

.heading_width {
  width: 900px;
}

.homepage_project_header_container {
  /* Frame 117 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  // width: 900px;
}

.homepage_project_heading {
  /* Frame 129 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  border: 1px solid #2ca4e6;
  border-radius: 56px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage__example_project_heading {
  /* Frame 129 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  border: 1px solid #2ca4e6;
  border-radius: 56px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  width: fit-content;
  margin-bottom: 3em;
}

.homepage_project_heading_content {
  /* Heading */

  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  text-align: center;
  text-transform: uppercase;

  color: #2ca4e6;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.homepage_project_heading_data_content,
.service_banner_heading {
  /* Heading */

  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  /* or 48px */
  text-align: center;

  /* Black */
  color: #000000;

  /* Inside auto layout */
  flex: none;
  // order: 1;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_project_heading_data_content_value {
  width: 900px;
}

.homepage_project_container_types {
  /* Frame 128 */

  /* Auto layout */
  display: flex;
  align-items: flex-start;
  padding: 0px;
  // gap: 32px;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.homepage_project_container_types_row {
  /* Row */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 3em;
}

.projects_gap {
  // gap: 5em;
}

.homepage_project_container_types_row_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 6px;
  width: 400px;
  background: #f8f8f8;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.homepage_box_height {
  // min-height: 409px;
  justify-content: flex-start;
  width: 350px;
}

.clients_carousel {
  width: 300px;
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_project_container_types_row_column_get {
  /* Auto layout */

  display: flex;
  flex-direction: column;
}

.homepage_certification_logo1_frame118 {
  padding: 25px;
}

.homepage_project_container_types_row_column_image {
  width: 99px;
  height: 90px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.clients_carousel_image {
  width: 90%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_project_container_types_row_column_title {
  text-wrap: pretty;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 140%;
  /* or 39px */
  text-transform: capitalize;

  color: #010305;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.homepage_project_container_action {
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.black {
  color: #000000;
  border-bottom: #000000 1.5px solid;
  margin: 100px 0;
}

.center {
  justify-content: center;
}

.homepage_project_container_types_row_column_heading1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 140%;
  /* or 39px */
  text-transform: capitalize;

  color: #010305;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: center;
}

.homepage_project_container_types_row_column_heading3 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3em;
  line-height: 140%;
  /* or 39px */
  text-transform: capitalize;

  color: #010305;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  min-height: 70px;
}

.homepage_project_container_types_row_column_sub {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 140%;
  text-transform: capitalize;
  color: #010305;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.homepage_certification_logo1_frame118_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.homepage_project_container_types_row_column_heading2 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  /* or 27px */

  color: #010305;

  //   opacity: 0.6;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.homepage_certification_logo1 {
  border-radius: 16px;
  background-color: #2ca4e6;
  // height: 252px;
  margin-bottom: 3em;
}

.homepage_certification_logo1_frame118_heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */
  text-align: center;

  padding: 0 0 20px 0;

  color: #333;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.homepage_certification_logo1_frame118_row {
  background: #0679b8;
  border-radius: 8px;
  display: flex;
  padding: 10px;
  height: 80px;
  width: 199px;
  align-items: center;
  justify-content: center;
}

.white_background {
  background: #fff;
}

.white_background > img {
  height: 100%;
  width: 100%;
}

.margindiv {
  border-bottom: 2px black solid;
  margin: 0 1em;
}

.footer {
  padding: 5em;
  display: flex;

  gap: 50px;
}

.bu

// /* Frame 135 */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// flex-wrap: wrap;
// align-items: flex-start;
// align-content: flex-start;
// padding: 0px 1px;
// gap: 0px 81px;

// width: 1311px;
// height: 233px;


// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;


// /* Frame 136 */

// width: 235px;
// height: 190px;


// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;


// /* logo 1 */

// position: absolute;
// width: 169px;
// height: 50px;
// left: 2.5px;
// top: 0px;

// background: url(logo.png);


// /* Social Icons */

// position: absolute;
// width: 39px;
// height: 38px;
// left: 2.5px;
// top: 80px;



// /* Vector */

// position: absolute;
// left: 0%;
// right: 0%;
// top: 0%;
// bottom: 0%;

// background: #0A66C2;


// /* SERVICES Personalized carbon credit strategy Quality analysis & risk mitigation Cost-efficient procurement Sign up */

.footer_links_container_heading {
  font-family: "Manrope";
  font-style: normal;
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 8px;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.footer_links_container_data {
  font-family: "Manrope";
  font-style: normal;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  margin-bottom: 8px;
  color: #000000;
  text-transform: uppercase;

  text-decoration: none;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.footer_links_container_data > * {
  text-decoration: none;
}

.services_banner_container {
  display: flex;
  margin: 1em auto;
  // width: 75%;
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.service_banner_data {
  flex: 2;
  // padding-top: 2em;
}

.service_banner_heading {
  font-size: 40px;
  text-align: left;
  margin-bottom: 1em;
  justify-content: start;
}

.service_banner_points {
  margin-bottom: 0.5em;
}

.service_banner_button {
  display: flex;
  margin-top: 40px;
}

.service_banner_image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_image {
  flex: none;
}

.service_banner_image_reverse {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom-bullet {
  list-style-type: none; /* Removes default bullets */
  padding-left: 0;
}

.custom-bullet li {
  position: relative;
  margin-left: 50px; /* Adjust space for custom bullet */
  margin-bottom: 15px;
}

.custom-bullet li::before {
  content: "•"; /* Unicode character for bullet */
  position: absolute;
  left: -50px; /* Position the bullet outside the text */
  font-size: 50px; /* Adjust bullet size */
  line-height: 1;
}

.bullet-point-container {
  padding-left: 0;
}

.bullet-point-item,
.bullet-point-item-header {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.bullet-point-item-para {
  margin-top: 1em;
}

.bullet-marker,
.bullet-marker-header {
  color: black; /* Or any color you prefer */
  font-size: 50px; /* Size of the bullet point */
  padding-right: 5px; /* Space between bullet and text */
  // padding-top: 10px;
}

.bullet-text,
.bullet-text-header {
  /* Styling for the text */
  font-size: 18px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  color: #010305;
  opacity: 0.6;
  /* flex: none; */
  /* order: 1; */
  align-self: stretch;
  flex-grow: 0;
}

.image_width {
  width: 100%;
}

.image_width1 {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.projects_image {
  width: 100%;
}

.knwoledge_container {
  display: flex;
  padding: 4em 5em;
  justify-content: space-evenly;
  gap: 4em;
  flex-wrap: wrap;
}

.knwoledge_container_learning {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.knwoledge_container_learning_image {
  flex: 1;
  border-radius: 16px;
  background-color: rgb(217, 217, 217, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.knwoledge_container_learning_data_heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  /* or 67px */
  letter-spacing: -0.03em;
  color: #010305;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.knwoledge_container_learning_data {
  display: flex;
  flex-direction: column-reverse;
  gap: 1em;
}

.knwoledge_container_learning_data_description {
  display: flex;
}

.aboout_mission {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
}

.mission_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.mission_container__image {
  width: 90%;
  height: 650px;
  background-size: cover;
  background-repeat: no-repeat;

  border: 5px solid #2ca4e6;
  border-radius: 16px;
}

.mission_box {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 450px;
  margin: 4em;
}

.mission_container_heading_about {
  color: #000000;
  font-size: 1em;
  text-transform: uppercase;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.64em;
}

.mission_projects_priciples {
  display: flex;
  // flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  gap: 2em;
}

.mission_home_projects_priciples {
  display: flex;
  // margin: 3rem 4rem;
  margin-bottom: 3em;
  gap: 3em;
}

.about_section_1 {
  justify-content: space-between;
  // display: flex;
}

.mission_container_heading {
  color: #000000;
  font-size: 3em;
  font-weight: 700;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
}

.mission_container_data {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 145.73%;
  /* or 26px */

  color: #000000;

  opacity: 0.7;
}

.mission_projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-bottom: 3em;
}

.mission_projects_home_references {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3em;
}

.mission_projects_priciples_box_image {
  background-color: #fff;
  padding: 1em;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.mission_home_projects_priciples_box_image {
  background-color: #fff;
  padding: 1em;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 10px;
}

.mission_projects_priciples_box {
  padding: 1em;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 400px;
  gap: 0.5em;
}

.mission_home_projects_priciples_box {
  padding: 1em;
  background-color: #f8f8f8;
  margin-bottom: 1rem;

  // display: flex;
  // flex-direction: column;
  border: 1px solid #f8f8f8;
  border-radius: 16px;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  // width: 400px;
  // gap: 0.5em;
}

.mission_projects_priciples_box_heading {
  font-size: 1em;
  color: #0b496b;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 145.73%;
  /* or 29px */

  color: #0b496b;
}

.mission_home_projects_priciples_box_heading {
  font-size: 1em;
  color: #0b496b;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 145.73%;
  margin-bottom: 5px;
  /* or 29px */

  color: #0b496b;
}

.mission_projects_priciples_box_job {
  padding-left: 0;
}

.mission_home_projects_priciples_box_job {
  padding-left: 0;
}

.mission_projects_priciples_box_job > * {
  padding-left: 2em;
}

.mission_home_projects_priciples_box_job > * {
  padding-left: 2em;
}

.mission_projects_priciples_box_job_heading {
  border-left: 5px solid #0b496b;
  padding-left: 1.5em;
  color: #0b496b;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.mission_home_projects_priciples_box_job_heading {
  border-left: 5px solid #0b496b;
  padding-left: 1.5em;
  color: #0b496b;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.mission_projects_priciples_box_job_labels {
  display: flex;
  gap: 0.5em;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 145.73%;
  /* or 20px */

  color: #0b496b;

  opacity: 0.7;
}

.mission_home_projects_priciples_box_job_labels {
  display: flex;
  gap: 0.5em;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 145.73%;
  /* or 20px */

  color: #0b496b;

  opacity: 0.7;
}

.testimonial_reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-top: 3em;
}

.testimonial_main,
.getting_started_main {
  display: flex;
  flex-direction: column;
}

.testimonial_reviews_heading {
  text-align: center;
}

.testimonial_reviews_box {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: space-evenly;
  // width: 90%;
}

.testimonial_home_reference_reviews_box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 3em;
}

.testimonial_review_box {
  // width: 400px;
  min-height: 500px;
  padding: 10px 24px 32px 24px;
}
.testimonial_reviews_box_child {
  width: 400px;
}
.testimonial_reviews_box_icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.testimonial_reviews_box_quote {
  transform: scale(-1, -1);
  font-size: 80px;
  color: #0b496b;
}

.testimonial_reviewer_position {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.testimonial_client_photo_data {
  display: flex;
  gap: 0.8em;
}

.testimonial_client_photo {
  border-radius: 50%;
}

.getting_started_boxes {
  display: flex;
  flex-direction: column;
  gap: 3em;
  margin: 0 auto;
  max-width: 1500px;
  min-width: 1000px;
  align-items: center;
}

.getting-started-form {
  width: 80%;
}

.getting_started_box_division {
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  width: 60%;
  margin: 0 auto;
  border-bottom: dotted black;
  border-bottom-width: 2px;
  border-bottom-style: dashed;
}

.getting_started_box_image {
  /* width: 100%; */
  // flex: 1 1;
  height: 644px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-color: #e6e6e6;
  margin: 1em 0 2em 0;
}

.getting_started_box_content {
  font-weight: bold;
  font-size: 2em;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* or 38px */
  letter-spacing: -0.03em;

  color: #010305;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.meetings-iframe-container > iframe {
  min-height: 690px !important;
  height: auto !important;
}

.leagal_notice_container {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: center;
}

.leagal_notice_container_heading {
  flex-direction: column;
  margin-top: 1em;
}

.leagal_notice_container_description {
  font-size: 0.5em;
  font-weight: 400;
}

.learn_section {
  margin: 5em 0;
}
.language-toggle {
  padding-left: 10px;
}

.language-toggle input[type="radio"] {
  display: none; /* Hide the actual radio buttons */
}

.language-toggle label {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  flex: 1; /* Equal width for both labels */
}

.language-toggle label:hover {
  background-color: #e0e0e0;
}

/* Style for selected label */
.language-toggle input[type="radio"]:checked + label {
  background-color: #2ca4e6;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  margin: 0 5px;
}

.switch > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #f36f25;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.switch > span.on {
  left: 0;
  padding-left: 4px;
  color: #f36f25;
}

.switch > span.off {
  right: 0;
  padding-right: 2px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 97px;
  height: 35px;
  background-color: #f36f25;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 48px;
  background-color: #fff;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #f36f25;
  border-radius: 60px;
  transition: left 0.2s ease-in-out;
}

input.check-toggle-round-flat:checked + label:after {
  left: 49px; /* Change the position to move the toggle */
}

.homepage_appbar_right_toggle {
  display: flex;
  align-items: center;
  gap: 1em;
}

.language-toggle-mobile {
  display: none;
}

.language-toggle-desktop {
  display: block;
}

.services_banner_container_mobile {
  display: none;
  width: auto;
}

.navbar-wrapper {
  width: 100%;
  position: relative; /* Ensure the wrapper occupies space on the page */
  z-index: 1000; /* Adjust z-index as needed */
  position: sticky;
  top: 0;
}

.homepage_appbar {
  position: sticky;
  top: 0;
}

.our-approach {
  font-size: 18px;
  margin: 0.8em 0;
  opacity: 1;
}

.about_mission_projects_priciples_box_image {
  padding: 0;
  background-color: transparent;
}

.about_container {
  flex: 1;
  // gap: 5em;
}
.about_banner_data {
  flex: 1;
}

.contact_us_email {
  display: flex;
  align-items: center;
  gap: 0.2em;
  text-decoration: none;
  color: inherit;
}

.contact_us_details {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

/* CSS for screens between 786px and 1200px */
@media screen and (max-width: 1200px) {
  .homepage_project_container {
    padding: 30px 10px;
  }

  .mission_home_projects_priciples {
    display: block;
  }

  .container_spacing {
    margin-bottom: 3em;
    width: auto;
  }

  .container_spacing_mobile {
    margin: 0 1em 3em 1em;
    width: auto;
  }

  .homepage_hero_container {
    flex-direction: column;
  }

  .homepage_project_heading_data_content {
    font-size: 25px;
  }

  .homepage_project_container_types_row_column {
    width: 380px;
  }

  .homepage_hero_container_video {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    left: -10px; /* Shifts the video 10px to the left */
    object-fit: cover;
  }

  .heropage_hero_action {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .homepage_hero_heading {
    height: auto;
    width: auto;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.03em;
    flex: none;
    // order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .homepage_hero_textbox {
    position: static;
    /* top: 50%; */
    /* right: -14%; */
    /* transform: translate(-50%, -50%); */
    transform: none;
    color: black;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 24px;
  }
}

.homepage_appbar {
  /* Styles for the navbar */
}

.homepage_appbar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger_icon {
  display: none;
  cursor: pointer;
  /* Add more styles for the icon */
}

.homepage_appbar_nav {
  /* Styles for the navigation */
}

.homepage_appbar_nav_links {
  display: flex;
}

.homepage_appbar_nav_link {
  padding: 0 10px;
  /* Add more link styles */
}

.homepage_project_container_scrollable {
  overflow-x: auto;
  white-space: nowrap; /* Ensure all items stay in a single line */
  max-width: 100%; /* Set a maximum width to ensure responsiveness */
  display: flex;
  margin: 0 4em;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.scroll-navigation,
.scroll-navigation-mobile {
  display: flex;
  justify-content: center;
}

.scroll-navigation-mobile {
  display: none;
}

.scroll-navigation button,
.scroll-navigation-mobile button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: lightgrey;
  margin: 0 5px;
  border: none;
}

.scroll-navigation button.active,
.scroll-navigation-mobile button.active {
  background-color: darkgrey;
}

.our_team_row {
  margin: 0 auto;
  justify-content: space-between;
  gap: 2em;
}

.our_team_row_column {
  width: 270px;
  padding: 24px;
  min-height: 330px;
  gap: 2em;
}

.our_team_member_photo_image {
  width: 150px;
  border-radius: 50%;
  // border: 10px solid #D1A540;
}

.member_name {
  font-size: 20px;
  line-height: 150%;
  color: #0b496b;
  font-weight: 700;
  display: flex;
  align-items: self-end;
  gap: 0.3em;
}

.member_details {
  font-size: 18px;
  line-height: 150%;
  color: #0b496b;
}

.member_social {
  display: flex;
  align-items: start;
  margin: 0.2em 0;
}

.our-approach_mobile {
  display: none;
}

.our_approach_desktop {
  display: block;
  margin-bottom: 3em;
}

.getting_started_contact_form {
  width: 80%;
  margin: 0 auto;
}

.contact_us_row_column {
  justify-content: center;
  width: 800px;
  flex-direction: row;
  align-items: center;
  gap: 2em;
}

.contact_us_photo_image {
  width: 300px;
}

.home_image_width {
  width: 60%;
}

.projects_data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
}
.projects_data > div {
  width: 46%;
}

.type_description {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.type_name {
  font-weight: 600;
  margin: 0.2em 0;
}

.project__icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.sdgs_icons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.homepage_project_container {
  padding: 2em 0 1em 0;
}

/* Popup.css */

/* Overlay for the entire screen */
.popup-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%; /* Cover the full height of the screen */
  background-color: rgba(0, 0, 0, 0.6); /* Dimmed background */
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align the pop-up to the bottom */
  transition: transform 1.5s ease-out; /* Slow down the transition */
  transform: translateY(100%);
  z-index: 1000; /* Ensure it appears on top of other content */
}

/* Content area of the pop-up */
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px 10px 0 0; /* Rounded corners for the top */
  width: 100%;
  height: 50%; /* Adjust the height as needed */
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2); /* Shadow for the pop-up */
  text-align: center;
  position: relative;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

/* Ensuring the popup is properly visible */
.popup-overlay.show {
  transform: translateY(0);
}

//   new pop up

.Overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 5;
}

.Overlay.Show {
  display: block;
}

.Modal {
  position: fixed;
  bottom: -150vh;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  padding: 0 12px 12px;
  transition: all 0.3s ease-out;
  z-index: 10;
}

.Modal.Show {
  bottom: 0;
}

.Close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: transparent;
  border: 0;
  font-size: 18px;
}

.Alert {
  position: fixed;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  top: 0;
  left: 0;
  color: #fff;
  background-image: linear-gradient(
    90deg,
    #ff9a8b 0%,
    #ff6a88 55%,
    #ff99ac 100%
  );
  margin: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
}

.Alert:empty {
  padding: 0;
}

.modal_container {
  padding: 3em 5em;
}

.modeal_container_content {
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modeal_container_content_button {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.modeal_container_content_button > button {
  width: 100%;
  font-weight: 600;
}

.modeal_container_content_button > button > a {
  text-decoration: none;
  color: inherit;
}

.modal_container_arrow {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.17em;
  font-weight: 700;
}

.learnMoreHeadingStyle {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  line-height: 140%;
}

.learn_more_data {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  line-height: 120%;
  text-align: center;
}

.service_steps_desktop {
  width: 55%;
  background-color: #f8f8f8;
  padding: 3.8em;
  height: 320px;
  border-radius: 20px;
  margin: 1em auto;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &:nth-child(1) {
    animation-name: flyInLeft;
  }

  &:nth-child(2) {
    animation-name: flyInRight;
  }

  &:nth-child(3) {
    animation-name: flyInBottom;
  }
}

@keyframes flyInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes flyInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes flyInBottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.service_steps_reverse_desktop {
  gap: 3em;
  width: 55%;
  background-color: #f8f8f8;
  padding: 2.2em;
  height: 320px;
  border-radius: 20px;
  margin: 1em auto;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &:nth-child(1) {
    animation-name: flyInLeft;
  }

  &:nth-child(2) {
    animation-name: flyInRight;
  }

  &:nth-child(3) {
    animation-name: flyInBottom;
  }
}

.service_steps_data_desktop,
.service_image_desktop {
  flex: 1;
}

.service_steps_banner_heading {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  justify-content: space-between;
  margin-bottom: 0;
  font-size: 2.01rem;
  color: #0b496b;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Diameter of the circle */
  height: 50px; /* Diameter of the circle */
  border: 2px solid #fff; /* Color and thickness of the border */
  border-radius: 50%; /* Makes the border circular */
  font-size: 25px; /* Font size of the number */
  color: #ffffff; /* Color of the number */
  background-color: #2ca4e6; /* Background color inside the circle */
  font-weight: bolder;
}

.service_image_width {
  width: 80%;
}

.service_banner_steps_image_width {
  justify-content: space-around;
}

.service_steps_image_width {
  width: 70%;
}

.add-bullet-gap {
  gap: 1em;
  align-items: center;
  margin: 1em 0;
}
.getting_started_custom {
  max-width: 100%;
}

.services_image_width_90 {
  width: 80%;
}

.three_steps_data {
  flex: none;
  width: 70%;
}

.three_steps_image {
  justify-content: center;
}

@media (max-width: 600px) {
  /* Make pop-up more responsive */
  .popup-content {
    width: 100%;
    height: 60%;
    padding: 10px;
  }
}

@media (min-width: 690px) and (max-width: 1200px) {
  .services_banner_container {
    width: 50%;
    margin: 2em auto;
  }
  .service_banner_image {
    justify-content: center;
  }

  .service_banner_image_reverse {
    justify-content: center;
  }
}

.full_width_getting_started_button > .heropage_hero_action {
  margin: 3em 0;
  justify-content: center;
}

.navbar_button_smallcase,
.homepage_appbar_nav_link_blue {
  text-transform: none;
  color: #0679b8;
}

// scrollbar

.scroller_tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.client-scroller {
  width: 100%;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: center;
  position: relative;
  height: 90px;
}

.client-scroller-track {
  display: flex;
  animation: scroll 20s linear infinite; /* Animate scrolling */
  white-space: nowrap;
  width: 10px; /* Total width of items + margins */
  position: absolute; /* Positioning it absolutely to control overflow */
  left: 0; /* Start at the left */
  align-items: center;
}

.client-scroller-track1 {
  display: flex;
  white-space: nowrap;
  width: 10px; /* Total width of items + margins */
  position: absolute; /* Positioning it absolutely to control overflow */
  left: 0; /* Start at the left */
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.client {
  flex-shrink: 0;
  width: 200px; /* Adjust the width of each client logo */
  margin-right: 30px; /* Adjust the spacing between the logos */
}

.client1 {
  flex-shrink: 0;
  width: 100px; /* Adjust the width of each client logo */
  margin-right: 30px; /* Adjust the spacing between the logos */
}

.client img {
  width: 120px;
  object-fit: contain;
  height: 70px;
}

.client1 img {
  width: 120px;
  object-fit: contain;
  height: 70px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-800px); /* Move left enough to hide images */
  }
}
.scroller_tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-scroller {
  width: 88%;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: center;
  position: relative;
  // height: 200px;
}

.project-scroller-track {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}
.project {
  flex-shrink: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  /* height: auto; */
  height: 185px;
  flex: 1 1 calc(33.33% - 1em); /* Adjust for 3 items per row, subtracting gap */
  box-sizing: border-box; /* Ensure padding and borders are included in width */
}

.project_image {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.project_image img {
  height: 100px;
}

.project_title {
  text-wrap: balance;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -2000px
    ); /* Adjust based on width of content (no gap) */
  }
}

.flags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; /* Align flags to the end of the container */
  width: 160px;
}

.homepage_certification_logo1_frame118_row img {
  width: 100%;
  object-fit: contain;
}

.mission_projects_priciples {
  display: flex;
  width: 100%;
  margin: 0 auto;
  gap: 3em;
  flex: 1;
}

// .mission_home_projects_priciples {
//   display: block;
// }

.homepage_how_it_works {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
}

.homepage_how_it_works a {
  width: 400px;
}

.testimonial_reviews_box_cover {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
  width: 100%;
}

.homepage_appbar_nav_link {
  position: relative; /* To contain the dropdown */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 150px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.homepage_appbar_nav_link:hover .dropdown-content {
  display: block; /* Show the dropdown on hover */
}

.homepage_appbar_nav_link {
  position: relative; /* Position relative for dropdown positioning */
  display: inline-block; /* Keep it inline with other nav items */
}

.dropbtn {
  background-color: #4caf50; /* Button color */
  color: white;
  padding: 10px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

.dropbtn:hover {
  background-color: #45a049; /* Darker green on hover */
}

.homepage_project_container_types_row1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 3em !important;
}

.dropdown-content {
  display: none; /* Hidden by default */
  position: absolute; /* Positioned relative to the parent */
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 160px; /* Width of the dropdown */
  z-index: 1;
  border-radius: 4px; /* Rounded corners */
  top: 90px; /* Position it below the navbar */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.dropdown-content a:hover {
  background-color: #f1f1f1; /* Light grey on hover */
}

.homepage_appbar_nav_link:hover .dropdown-content {
  display: block; /* Show dropdown on hover of the parent */
}

.factSheet_container {
  display: flex;
  margin: 1em auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.our_projects_box {
  width: 400px;
}

.homepage_project_heading {
  margin-bottom: 3em;
}

.navbar_margin_zero {
  margin: 0;
}

/* Add any other custom styles below */

@media only screen and (max-width: 1200px) {
  .homepage_appbar_nav_link {
    position: relative;
  }

  .dropdown-content {
    display: none; /* Hidden by default */
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    min-width: 160px;
    z-index: 1;
  }

  .homepage_appbar_nav_link.open .dropdown-content {
    display: block; /* Show dropdown when the class 'open' is added */
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  .homepage_project_heading {
    margin-bottom: 3em;
  }
  .navbar_margin_zero {
    margin: 0;
  }

  .modal_container {
    padding: 2em 1em;
  }

  .modeal_container_content {
    margin: 0;
    flex-direction: column;
  }

  .modal_container_arrow {
    font-size: 0.9em;
  }

  .learnMoreHeadingStyle {
    font-size: 1.5em;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  .learn_more_data {
    font-size: 1.2em;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }

  .modeal_container_content_button > button {
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
  }

  .modeal_container_content_button > a {
    width: 320px;
  }

  .home_image_width {
    width: 100%;
  }

  .homepage_project_container {
    padding: 30px 10px;
  }

  .homepage_project_heading_data_content {
    font-size: 19px;
  }

  .homepage_project_container_types_row_column {
    width: 90%;
  }

  .homepage_certification_logo1 {
    border-radius: 16px;
    background-color: #2ca4e6;
    /* height: 252px; */
    margin-bottom: 3em;
    padding: 30px 0;
  }

  .homepage_certification_logo1_frame118_content {
    gap: 10px;
  }

  .homepage_certification_logo1_frame118_row {
    width: 160px;
    height: 60px;
  }

  .homepage_certification_logo1_frame118_heading {
    padding: 20px 30px;
  }

  .margindiv {
    margin: 0 15px;
  }

  .footer {
    padding: 20px;
    gap: 40px;
    flex-direction: column;
  }

  .homepage_certification_logo1_frame118 {
    padding: 20;
  }

  .homepage_hero_textbox {
    position: static;
    /* top: 50%; */
    /* right: -14%; */
    /* transform: translate(-50%, -50%); */
    transform: none;
    color: black;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 24px;
  }

  .homepage_hero_heading,
  .homepage_hero_text {
    color: #000000;
  }

  .projects_gap {
    // gap: 1em;
  }

  .homepage_hero_text {
    width: auto;

    height: auto;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }

  .heading_width {
    width: 100%;
  }
  .homepage_project_heading_data_content_value {
    width: 100%;
  }

  .mission_projects_priciples_box {
    flex: none;
  }

  .homepage_hero_heading {
    height: auto;
    width: auto;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: -0.03em;
    flex: none;
    // order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .homepage_hero_frame129_heading {
    color: #000000;
  }

  .homepage_hero_frame129 {
    border: 1px solid #000000;
  }

  .services_banner_container {
    display: flex;
    margin-bottom: 3em;
    flex-direction: column-reverse;
  }

  .service_banner_button {
    justify-content: center;
  }

  .knwoledge_container {
    padding: 2em;
  }

  .mission_box {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: auto;
    margin: 3em 1em;
  }

  .mission_container__image {
    width: 90%;
    height: 650px;
    /* background-size: contain; */
    background-position-x: right;
    background-position-y: 49px;
    /* background: bottom; */
    background-repeat: no-repeat;
    border: 5px solid #2ca4e6;
    border-radius: 16px;
  }

  .testimonial_reviews_box {
    justify-content: center;
  }

  .testimonial_home_reference_reviews_box {
    justify-content: space-between;
    margin-bottom: 3em;
    width: 100%;
  }

  .getting_started_box_image {
    height: 300px;
  }

  .getting_started_boxes {
    gap: 3em;
    margin: 0em auto;
    flex-direction: column;
    max-width: none;
    min-width: 100%;
  }
  .homepage_appbar_container {
    gap: 0;
  }

  .homepage_appbar_right {
    gap: 0px;
  }

  .language-toggle-mobile {
    display: block;
  }

  .language-toggle-desktop {
    display: none;
  }

  .getting_started_box {
    display: flex;
    /* flex: 1 1; */
    flex-direction: column;
  }

  .services_banner_container_mobile {
    flex-direction: column;
  }

  .services_banner_container_desktop {
    display: none;
  }

  .services_container_spacing {
    // width: 100%;
  }

  .services_banner_container {
    width: auto;
    margin: 2em 1em;
  }

  .mission_home_projects_priciples {
    margin-bottom: 3rem;
    display: block;
  }

  .bullet-point-item,
  .bullet-point-item-header {
    // flex-direction: column;
  }

  .service_banner_heading {
    font-size: 2em;
  }
  .homepage_project_container_types_row_column_heading1 {
    font-size: 1.3em;
  }

  .cookie-banner {
    padding: 0.5em !important;
  }
  .cookie-button {
    background: rgb(44, 164, 230) !important;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    color: white;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 0.8em 2em !important;
    margin: 15px 10px 15px 0px !important;
    font-size: 16px;
    width: 140px !important;
  }

  .hamburger_icon {
    display: block;
  }

  .homepage_appbar_nav {
    display: none;
  }

  .homepage_appbar_nav.open {
    display: block;
    /* Add styling for the mobile menu */
  }

  .homepage_appbar {
    padding: 0;
    padding-left: 15px;
  }

  /* Add more mobile-specific styles here */
  .homepage_project_container_scrollable {
    margin: 0 1em;
  }

  .bullet-text,
  .bullet-marker {
    color: #000000 !important;
  }
  .bullet-text-header {
    font-size: 18px !important;
  }

  .homepage_hero_textbox > .bullet-marker,
  .bullet-text {
    color: black !important;
  }

  .getting_started_main {
    gap: 3em;
  }

  .our_team_row {
    width: min-content;
  }

  .our-approach_mobile {
    flex-direction: column-reverse;
    align-items: center;
  }

  .service_banner_heading {
    font-size: 1.2em;
    text-align: center;
  }

  .our-approach_mobile {
    display: flex;
  }

  .our_approach_desktop {
    display: none;
  }

  .aboout_mission {
    gap: 1em;
  }

  .getting_started_contact_form {
    width: 90%;
    margin: 0 auto;
  }

  .homepage_project_container_types_row_column_sub {
    width: 90%;
    margin: 0 auto;
  }

  .contact_us_row_column {
    flex-direction: column;
  }

  .contact_us_photo_image {
    width: 200px;
  }

  .homepage_how_it_works a {
    width: inherit;
  }

  .contact_us_details {
    font-size: 15px;
  }

  .about_container {
    gap: 1em;
  }
  .homepage_project_container_types_row_column_heading3 {
    font-size: 1em;
  }
  .type_name,
  .type_description {
    font-size: 0.8em;
  }
  .our_projects_box {
    width: 430px;
  }

  .homepage_project_container_types {
    /* display: flex; */
    align-items: flex-start;
    padding: 0px;
    // gap: 32px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    flex-wrap: wrap;
    justify-content: center;
    // width: 90%;
    padding: 30px 10px;
  }

  .homepage_project_container_types_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    width: 100%;
    margin-bottom: 3em;
  }

  .homepage_project_container_types_row1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 3em !important;
  }

  .homepage_how_it_works {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
    width: 100%;
  }

  .scroller_tab {
    margin: 0;
  }

  .testimonial_reviews {
    gap: 1em;
  }

  .mission_projects_priciples {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .mission_projects_priciples_box {
    width: 90%;
  }

  .mission_projects {
    margin: 0;
  }

  .service_image_width {
    width: 100%;
  }

  .about_section_1 {
    justify-content: center;
  }
  .scroll-navigation-mobile {
    display: flex;
  }
  .scroll-navigation {
    display: none;
  }
  .service_steps_banner_heading {
    align-items: center;
  }
  .three_steps_image {
    width: 80%;
    margin: 0 auto;
  }
  .invisible_boxes {
    display: none;
  }

  .mission_projects {
    margin: 0;
    margin-bottom: 2em;
    padding: 30px 10px;
  }
  .our_team_row_column {
    width: 300px;
    padding: 24px;
    min-height: 330px;
  }

  .getting_started_box {
    width: 400px;
  }

  .getting-started-form {
    width: 100%;
  }

  .client-scroller-track1 {
    position: static;
    gap: 1em;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

// new css

.content_wrapper {
  margin: 3rem 5%;
}

.services_banner_container_spacing {
  margin: 0;
}

.homepage_step_project_header_container {
  /* Frame 117 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 3em;
  // width: 900px;
}

.services_how_it_works_banner_container {
  display: flex;
  margin: 1em auto;
  // width: 75%;
  align-items: center;
}

.services_how_it_works_banner_container_mobile {
  display: none;
  width: auto;
}

.services_how_it_works_banner_container_desktop {
  display: none;
}

.how_it_works_steps_wrapper {
  margin-bottom: 3em;
}

.services_how_it_works_banner_container_mobile {
  display: none;
  width: auto;
}

@media screen and (max-width: 1200px) {
  .content_wrapper {
    margin: 3em 2em;
  }
  .services_how_it_works_banner_container_mobile {
    flex-direction: column;
  }

  .services_how_it_works_banner_container {
    width: 50%;
    margin: 2em auto;
    display: flex;
    margin: 2em 2em;
    flex-direction: column-reverse;
  }

  .services_how_it_works_banner_container_mobile {
    flex-direction: column;
  }

  .services_how_it_works_banner_container_desktop {
    display: none;
  }

  .services_how_it_works_banner_container {
    width: auto;
    margin: 2em 1em;
  }
}

@media screen and (max-width: 767) {
  .content_wrapper {
    margin: 3em 1em;
  }
}

.mb-3 {
  margin-bottom: 3em;
}

// FAQ

.faqContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: 3em;
}

.faqList {
  margin-top: 20px;
}

.faqItem {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faqQuestion {
  width: 100%;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqAnswer {
  padding: 10px 0;
  font-size: 16px;
  color: #555;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.content-wrapper {
  max-width: 800px;
  width: 100%;
}

.faq-list {
  margin-top: 1.5rem;
}

.faq-item {
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
}

.faq-question {
  width: 100%;
  background: none;
  border: none;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
  font-weight: bold;
  transition: color 0.3s;
}

.faq-question:hover {
  color: #007bff;
}

.faq-answer {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #333;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
